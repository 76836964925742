import "./App.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Auth/Login/Login";
import DefaultLayout from "./components/Layout/DefaultLayout/DefaultLayout";
import Explore from "./components/Explore/Explore";
// import CalculateRefund from "./components/RefundModal/CalculateRefund/CalculateRefund";
import axios from "axios";
import { GlobalState } from "./context/GlobalContext";
import BusinessPlanning from "./components/BusinessPlanning/BusinessPlanning";
import BusinessPlanningProvider from "./context/BusinessPlanningContext";
import SupplierForm from "./components/SupplierPage/SupplierForm/SupplierForm";
import DefaultOpenLayout from "./components/Layout/DefaultOpenLayout/DefaultOpenLayout";
import AuthProvider from "./context/AuthContext";
import SupplierDashboard from "./components/SupplierPage/SupplierDashbord/SupplierDashboard";
import OwnProductionCostingDashboard from "./components/OwnProductionCosting/OwnProductionCostingDashbord/OwnProductionCostingDashboard";
// import CategoryLevelBp from "./components/BusinessPlanning/CategoryLevelBp/CategoryLevelBp";
import UcVsGynesis from "./components/UcVsGynesis/UcVsGynesis";
import Myntra_reco from "./components/MyntraReco";
import EcomReports from "./components/EcomReports/EcomReports";
import Store from "./components/BusinessPlanning/Store/Store";
import SubCategoryLevelBp from "./components/BusinessPlanning/Store/SubCategoryLevelBp/StoreLevelBp/SubCategoryLevelBp";
import { StoreProvider } from "components/EboStore/EboStore";
import EBOPayments from "components/EBOPayments/EBOPayments";
import Users from "components/Users/Users";
import Dashboard from "components/Layout/Dashboard/Dashboard";
import SupplierUser from "components/SupplierPage/SupplierUser/SupplierUser";
import DashboardSupplierUser from "components/SupplierPage/SupplierDashbord/SupplierUser/SupplierUser";
import { supplierDrawerElements, supplierUrlParamName } from "data/supplierPageData";

import { webDataDrawerElements, webDataParamName } from "data/radhamaniPageData";
import DashboardRWebData from "components/RadhamaniWebData/RadhamaniWebData";
import ChangePassword from "components/Auth/ChangePassword/ChangePassword";
import { rootProtectedPath, services } from "data/authData";
import DashboardWithMiniCollapsableDrawer from "components/Layout/DashboardWithMiniCollapsableDrawer/DashboardWithMiniCollapsableDrawer";
import { pimDrawerElements, pimParamName } from "data/pimData";
import PimDashboard from "components/Pim/PimDashboard/PimDashboard";
import MboStore from "components/MboStore/MboStore";
import PimProvider from "context/PimContext";
import { itemMasterDrawerElements, itemMasterTabParamName } from "data/itemMasterData";
import ItemMasterDashboard from "components/ItemMaster/ItemMaster";

import DataHub from "components/DataHub/DataHub";
import CustomQueries from "components/CustomQueries/CustomQueries";
import { DataHubDrawerElements, DataHubTabParamName } from "data/DataHubData";
import POSReco from "components/POSReco/POSReco";
import { myntra_reco_drawer, myntraRecoUrlParamName } from "data/myntraRecoData";
import PimExplore from "components/Pim/PimExplore/PimExplore";
import LoadingScreen from "components/commonComponents/LoadingScreen";
import DataHubStore from "components/DataHub/DataHubStore/DataHubStore";
import DataHubStoreView from "components/DataHub/DataHubStore/DataHubStoreView";
import StoreSummary from "components/EboStoreV3/Summary/StoreSummary";
import StoreList from "components/EboStoreV3/StoreList/StoreList";
import StoreDetail from "components/EboStore/ActiveStore/ActiveStore";
// import FormDialog from "components/EboStoreV3/Form/Form";

function App() {
	const { snackBar, snackBarOpen, setSnackBarOpen } = GlobalState();
	axios.defaults.baseURL = process.env.REACT_APP_API_URL;
	axios.defaults.headers["Content-Type"] = `application/json`;

	return (
		<Box className="App">
			{/* for alert messages */}
			<LoadingScreen />
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={4000}
				onClose={() => setSnackBarOpen(false)}
				anchorOrigin={snackBar.anchorOrigin}>
				<Alert onClose={() => setSnackBarOpen(false)} severity={snackBar.severity} sx={{ width: "100%" }}>
					{snackBar.message}
				</Alert>
			</Snackbar>

			<Routes>
				<Route path="/" element={<Login />} />
				{/* only explore has authentication */}
				<Route
					path={rootProtectedPath}
					element={
						<AuthProvider>
							<DefaultLayout />
						</AuthProvider>
					}>
					<Route index element={<Explore />} />
					{/* <Route path="calc_refund_RM" element={<CalculateRefund />} /> */}
					<Route path={services.supplier_dashboard.path}>
						<Route index element={<SupplierDashboard />} />
						<Route
							path="supplier_info"
							element={
								<Dashboard drawerList={supplierDrawerElements} searchParamName={supplierUrlParamName} navSpace={0} />
							}>
							<Route index element={<DashboardSupplierUser />} />
						</Route>
					</Route>
					<Route path={services.ebo_stores.path} element={<StoreProvider />} />
					{/* <Route path={services.ebo_stores.path}>
						<Route index element={<StoreSummary />} />
						<Route path="store-list" element={<StoreList />} />
						<Route path="store-detail" element={<StoreDetail />} />
						<Route path="store-form" element={<Form />} />
					</Route> */}
					<Route path={services.production_costing.path} element={<OwnProductionCostingDashboard />} />
					<Route path={services.mbo_stores.path} element={<MboStore />} />

					<Route path={services.uc_vs_ginesys.path} element={<UcVsGynesis />} />
					<Route path={services.pos_reco.path} element={<POSReco />} />
					<Route path={services.ecom_reports.path} element={<EcomReports />} />
					<Route path={services.ebo_payments.path} element={<EBOPayments />} />
					<Route path={services.users.path} element={<Users />} />
					<Route
						path={services.data_hub.path}
						element={<Dashboard drawerList={DataHubDrawerElements} searchParamName={DataHubTabParamName} navSpace={0} />}>
						<Route index element={<DataHub />} />
					</Route>
					<Route path={services.custom_queries.path} element={<CustomQueries />} />
					<Route
						path={services.radhamani_web_data.path}
						element={<Dashboard drawerList={webDataDrawerElements} searchParamName={webDataParamName} navSpace={0} />}>
						<Route index element={<DashboardRWebData />} />
					</Route>
					<Route
						path={services.item_master.path}
						element={
							<Dashboard drawerList={itemMasterDrawerElements} searchParamName={itemMasterTabParamName} navSpace={0} />
						}>
						<Route index element={<ItemMasterDashboard />} />
					</Route>
					<Route
						path={services.online_reco.path}
						element={<Dashboard drawerList={myntra_reco_drawer} searchParamName={myntraRecoUrlParamName} navSpace={0} />}>
						<Route index element={<Myntra_reco />} />
					</Route>
					<Route
						path={services.pim.path}
						element={<Dashboard drawerList={myntra_reco_drawer} searchParamName={myntraRecoUrlParamName} navSpace={0} />}>
						<Route index element={<Myntra_reco />} />
					</Route>
					<Route
						path={services.pim.path}
						element={<Dashboard drawerList={myntra_reco_drawer} searchParamName={myntraRecoUrlParamName} navSpace={0} />}>
						<Route index element={<Myntra_reco />} />
					</Route>
					<Route path={services.pim.path}>
						<Route index element={<PimExplore />} />

						{/* <Route
							path={`dashboard`}
							element={
								<PimProvider>
									<DashboardWithMiniCollapsableDrawer
										drawerList={pimDrawerElements}
										searchParamName={pimParamName}
										navSpace={0}
										service={services.pim}>
										<PimDashboard />
									</DashboardWithMiniCollapsableDrawer>
								</PimProvider>
							}
						> */}

						{/* </Route> */}
						{Object.keys(pimDrawerElements)
							.reduce((acc, row) => {
								const service = pimDrawerElements[row];
								if (service?.types && typeof service?.types === "object") {
									Object.keys(service.types).forEach((key) => {
										acc.push(service.types[key]);
									});
								} else acc.push(service);
								return acc;
							}, [])
							.map((row) => {
								return (
									<Route
										path={row.key}
										key={row.key}
										element={
											<DashboardWithMiniCollapsableDrawer
												drawerList={pimDrawerElements}
												searchParamName={pimParamName}
												navSpace={0}
												service={services.pim}
												pathName={row.key}
											/>
										}>
										<Route index element={<PimDashboard />} />
									</Route>
								);
							})}
					</Route>

					{/* <Route path="business_planning" element={<BusinessPlanningProvider />}>
						<Route index element={<BusinessPlanning />} />
						<Route path="department_level_bp" element={<CategoryLevelBp />} />
					</Route> */}

					<Route path={services.business_plan.path} element={<BusinessPlanningProvider />}>
						<Route index element={<BusinessPlanning />} />
						<Route path="store" element={<Store />} />
						<Route path="category" element={<SubCategoryLevelBp />} />
					</Route>
				</Route>

				{/* Routes with no auth */}
				<Route path="open" element={<DefaultOpenLayout />}>
					<Route index element={<SupplierForm />} />
					<Route path="supplier" element={<SupplierForm />} />
				</Route>

				<Route path="change_password" element={<ChangePassword />} />

				<Route path="external">
					<Route
						path="supplier"
						element={
							<AuthProvider>
								<Dashboard drawerList={supplierDrawerElements} searchParamName={supplierUrlParamName} navSpace={10} />
							</AuthProvider>
						}>
						<Route index element={<SupplierUser />} />
					</Route>
					<Route
						path="store"
						element={
							<AuthProvider>
								<DefaultLayout />
								{/* <DataHubStoreView /> */}
							</AuthProvider>
						}>
						<Route index element={<DataHubStoreView />} />
					</Route>
				</Route>
			</Routes>
		</Box>
	);
}

export default App;
